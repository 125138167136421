import { useEffect, useState } from 'react'
import firebase from "firebase/compat/app";
import 'firebase/compat/firestore';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import SignIn from './pages/Signin'
import './App.css';
import Dashboard from "./pages/Dashboard";
import ScreensWall from "./pages/ScreensWall";
import AddMenu from "./components/AddMenu";
import Layout from './pages/_layout';

const App = () =>  {
  const [menus, setMenus] = useState([] as any);

  const menusRef = firebase.firestore().collection("/menus");

  const onDataChange = () => {
    menusRef.get().then((snapshot: any) => {
      let menuList:any = [];
      snapshot.forEach((childSnapshot: any) => {
        const pageId = childSnapshot.id;
        const name = childSnapshot.data().name;
        const path = childSnapshot.data().path;
        const template = childSnapshot.data().template;
        const endpoint = childSnapshot.data().endpoint;
        const api_key = childSnapshot.data().api_key;
        const space_id = childSnapshot.data().space_id;
        const studio_url = childSnapshot.data().studio_url;
        const player_url = childSnapshot.data().player_url;
        const camera_url = childSnapshot.data().camera_url;
        menuList.push({ pageId, name, path, template, endpoint, api_key, space_id, studio_url, player_url, camera_url });
      });
      setMenus(menuList);
    });
  };

  useEffect(() => {
    const unsubscribe = menusRef.onSnapshot(onDataChange);
    return () => unsubscribe();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route path="/add" element={<AddMenu />} />

        <Route path="/dashboard" element={
          <Layout menus={menus}>
            <Dashboard menus={menus} />
          </Layout>
        } />

        {menus.map((menu:any) => (
          <Route key={menu.path} path={`/${menu.path}`} element={
            <Layout menus={menus}>
              <ScreensWall
                pageId={menu.pageId}
                name={menu.name}
                template={menu.template}
                menus={menus} 
                endpoint={menu.endpoint}
                api_key={menu.api_key}
                space_id={menu.space_id}
                studio_url={menu.studio_url}
                player_url={menu.player_url}
                camera_url={menu.camera_url}
                />
            </Layout>
          } />
        ))}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
